/**
 * Created by john on 27/2/17.
 */

'use strict';

/* exported
dateTimezoneFilter
 */

function dateTimezoneFilter(moment) {
  return function(date, timezone, format) {
    var formatString = format || 'MMM D, YYYY';

    return moment.tz(date, timezone).format(formatString);
  };
}

/**
 * Created by john on 4/3/17.
 */
'use strict';

/* exported
dateAgoFilter
 */

function dateAgoFilter(moment) {
  return function(date) {
    return moment(date).fromNow();
  };
}

/**
 * Created by john on 8/5/17.
 */
'use strict';

/* exported
 dayOfWeekFilter
 */

function dayOfWeekFilter() {
  return function(dayInNumber) {
    var dayOfWeek;

    switch (dayInNumber) {
      case 0:
        dayOfWeek = 'Sunday';
        break;
      case 1:
        dayOfWeek = 'Monday';
        break;
      case 2:
        dayOfWeek = 'Tuesday';
        break;
      case 3:
        dayOfWeek = 'Wednesday';
        break;
      case 4:
        dayOfWeek = 'Thursday';
        break;
      case 5:
        dayOfWeek = 'Friday';
        break;
      case 6:
        dayOfWeek = 'Saturday';
    }

    return dayOfWeek;
  };
}

'use strict';

/* exported
tickDurationFilter
 */

function tickDurationFilter(moment) {
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  return function(duration) {
    var momentDuration = moment.duration(duration);

    return pad(momentDuration.hours(), 2, '0') + ':' + pad(momentDuration.minutes(), 2, '0');
  };
}

'use strict';

/* exported
localeStringFilter
 */

function localeStringFilter() {
  return function(amount, locale, currency, style) {
    locale = locale || 'US';
    currency = currency || 'USD';
    style = style || 'currency';

    return (amount!== undefined && amount.toLocaleString(locale, {currency: currency, style: style})) || '';
  };
}

'use strict';

/* exported
priceFormatFilter
 */

function priceFormatFilter() {
  return function(priceInSmallestCurrency, currency) {
    currency = currency || 'usd';

    switch (currency) {
      default:
        return priceInSmallestCurrency / 100;
    }
  };
}

'use strict';

/* exported
nowDatePlusDates
 */

function nowDatePlusDates(moment) {
  return function(expiredInDays) {
    return moment().add(expiredInDays, 'days').format('MMM DD, YYYY');
  };
}

'use strict';

/* exported
minutesToHHmmFilter
 */

function minutesToHHmmFilter(moment) {
  return function(minutes) {
    var time = moment.duration(minutes, 'minutes');
    var timeMinute = time.minutes();
    var timeHours = time.hours();
    if (timeMinute < 10){
      timeMinute = '0' + timeMinute;
    }
    if (timeHours < 10){
      timeHours = '0' + timeHours;
    }
    return timeHours + ':' + timeMinute;
  };
}


 function priceFormatToFixedFilter() {
   return function(price) {
    let res = price;
    if (isNaN(res)) {
      res = 0;
    }
    const priceToFixed = parseFloat(((res || 0) + 0.001).toFixed(2));
    var parts = priceToFixed.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    res = parts.join(".");
    if (res.indexOf('.') === -1) {
      res = res + '.00';
    }else {
      let sub = res.substring(res.indexOf('.') + 1);
      if (sub.length < 2) {
        res = res + '0';
      }
    }
    return res;
   };
 }
